import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Which industries does your UI UX design agency in Bangalore serve?",
  "How do you approach a UI UX design project?",
  "What makes Octet stand out as the best UI UX design company in Bangalore?",
  "What is the standard timeline for a UI UX design project?",
  "Can your UI UX design company create web and mobile apps?",
];

const bangalore = () => {
  const banner = {
    title: `UI UX Design Agency in Bangalore`,
    content:
      "We are a research-backed <strong class='font-semibold'><a title='UI UX design agency in Bangalore' class='link-text-para' href='/'>UI UX design agency in Bangalore</a></strong>. We provide design excellence to enhance your business success.",
    img: ["why-octet-bangalore.webp"],
    imageTitle: "UI UX Design Company, Bangalore",
    imageAlt: "Octet Design Studio- UI UX Design Agency in Bangalore",
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "A Property Management Platform",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp"],
    },
    {
      title: "Survey2Connect",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      link: "/project/survey2connect/",
      firstAlt: "UI UX Design Screen to Create and Build Surveys",
      firstTitle: "Survey Builder UI Screen",
      secondAlt: "UI UX Design to add and customise Questions in Survey Builder",
      secondTitle: "UI Design to Create Questionnaire",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["survey.webp", "survey-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Being the best website design company in Bangalore, we have experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions.`
      ],
    },
    {
      para: [
        `Octet, the best web design company in Bangalore, collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. The approach of our <strong class='font-semibold'>mobile app design company in Bangalore</strong> involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing.`,
      ],
    },
    {
      para: [
        `Our team of exceptional designers, proactive approach, and dedication to innovation make our website design agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner.`,
      ],
    },
    {
      para: [
        `The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months.`,
      ],
    },
    {
      para: [
        `Yes, we are known for being the leading website design agency and mobile app design company in Bangalore. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes.`,
      ],
    },
  ];
  const lastLength = projectData;
  
  const info = {
    title: "Craft Brilliance with the top UI UX Design Agency in Bangalore",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: `Choose the Top UI UX Design Agency in Bangalore`,
    des: "Our <strong class='font-semibold'>website design agency in Bangalore</strong> crafts human-centered experiences for digital transformation and drives innovation through UX UI design.",
    items: [
      {
        title: "User Research",
        content:
          "Our research-driven approach enables us to create tailored interfaces that align with your business objectives. As the best website design company in Bangalore, we enhance online visibility and user experience.",
        link: "/user-research/",
        linkText: `Our <a href="/user-research/" title="User research agency">User Research Agency</a> develops strategies to enhance digital visibility and user experience.`
      },
      {
        title: "UI UX Designing",
        content: "Octet is the <strong class='font-semibold'>best web design company in Bangalore</strong>, offering design solutions that bring your digital vision to life. We fuse functionality to design attractive interfaces that provide engaging user experiences.",
        link: "/ui-ux-designing/",
        linkText: `Our <a href="/ui-ux-designing/" title="UI UX Design Agency">UI UX design agency</a> has a plethora of experience providing customized solutions for various industries.`
      },
      {
        title: "Usability Testing",
        content:
          "We use advanced usability metrics to identify user pain points accurately and fine-tune the interface elements for more promising engagement. Our UI UX design company in Bangalore employs advanced techniques to optimize your user experiences.",
        link: "/usability-testing/",
        linkText: `Our <a href="/usability-testing/" title="Usability Testing Company">Usability Testing Company</a> uses improved procedures to ensure your user experiences are well-optimized.`
      },
      {
        title: "UI Development",
        content:
          "We craft dynamic, engaging platforms that captivate users and increase their sessions. As Bangalore's ultimate UI UX design company, we craft custom user interfaces using design principles, programming languages, and core industry expertise.",
        link: "/ui-development/",
        linkText: `Our <a href="/ui-development/" title="UI Development Company">UI development company</a> creates engaging experiences by putting user-centric design upfront.`
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We've compiled frequently asked questions to provide quick and informative answers about our web design company, UI UX design services, creation process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "<strong class='font-semibold'><a href='/' title='Octet design studio' class='link-text-para'>Octet</a></strong> is a <strong class='font-semibold'>web design agency in Bangalore</strong> with over years of experience transforming SaaS, B2B, and enterprise-grade products. Our proactive approach simplifies complex applications, delivering intuitive, user-centric solutions across diverse industries. Being the leading app design agency, we operate with an agile process that empowers businesses to unlock their potential and create functional, visually appealing products."
  ];
  const knowAboutTitle = "Work with the Best Website Design Company in Bangalore";
  const commonContent = {
    title: `Elevate Digitally with Best <span class='h1-span'> Web Design Company</span> in Bangalore`,
    para: ['From concept to launch, we are with you at every stage!'],
    content: [
      {
        title: "Cross-Platform Adaptability",
        text: "We prioritize responsive designs that work seamlessly across all devices and screen sizes, enhancing user accessibility. We are your go-to web design agency in Bangalore, aiming to deliver an exceptional user experience tailored to your needs.",
      },
      {
        title: "User-Centric Approach",
        text: "We adopt a user-centric approach to product development and prioritize their needs and preferences. Our website design company customises the product to meet the target market's expectations and ensure customer satisfaction.",
      },
      {
        title: "Continuous Improvement",
        text: "Our design methodology is based on a continuous improvement process informed by extensive user feedback & market data. Our <strong class='font-semibold'>UI UX design company in Bangalore</strong> ensures that our designs align with user preferences.",
      },
      {
        title: "Increased Conversion Rates",
        text: "Our web design company's approaches to design optimization are grounded in data-driven insights and proven strategies. This results in a website that effectively engages and converts its audience.",
      }
    ],
  };
  const commonContent1 = {
    title: "Process Followed by Our Website Design Company in Bangalore",
    para: ['Time-tested design approaches that lead to success for our clients.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX design company in Bangalore carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to fully grasp their issues. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our website design company strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "We formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with a visually appealing, user-friendly interface that enhances the user experience and maximizes your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "Upon finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our web design agency in Bangalore to to evaluate the final product, ensuring it meets the required standards and specifications.",
      }
    ],
  }
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Which industries does your UI UX design agency in Bangalore serve?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Besing the best website design company in Bangalore, we have experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions."
                }
              },{
                "@type": "Question",
                "name": "How do you approach a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Octet, the best web design company in Bangalore, collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. The approach of our mobile app design company in Bangalore involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing."
                }
              },{
                "@type": "Question",
                "name": "What makes Octet stand out as the best UI UX design company in Bangalore?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our team of exceptional designers, proactive approach, and dedication to innovation make our website design agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner."
                }
              },{
                "@type": "Question",
                "name": "What is the standard timeline for a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months."
                }
              },{
                "@type": "Question",
                "name": "Can your UI UX design company create web and mobile apps?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we are known for being the leading website design agency and mobile app design company in Bangalore. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes."
                }
              }]
            }
        `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          page={"ahmedabad-home"}
          category="bangalore"
          location="WeWork Cherry Hills, Embassy Golf Links Business Park, Challaghatta, Bengaluru - 560071"
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout des={knowAboutDes} title={knowAboutTitle} imageAlt={"Logo of UI UX Design Agency in Bangalore"} imageTitle={"UI UX Design Agency's Logo"} />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent data={commonContent1} mode={"light"} list='single' nopara />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Turning Good Ideas into <span className="h1-span">Great Designs</span>
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default bangalore;

export const Head = () => (
  <Seo
    title="UI UX Design Agency in Bangalore | Best Website Design Company"
    description="Transform your brand with our UI UX design agency & the best website design company in Bangalore to enhance user engagement and satisfaction."
  />
);
